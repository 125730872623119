import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  // --- Grid ---
  section: {
    backgroundColor: 'white',
    position: 'relative'
  },
  mainSection: {
    paddingTop: 140,
    paddingBottom: 60,
    '@media only screen and (max-width: 960px)': {
      paddingTop: 100
    }
  },
  secondarySection: {
    paddingTop: 100,
    paddingBottom: 40,
    '@media only screen and (max-width: 425px)': {
      paddingTop: 50,
      paddingBottom: 20
    }
  },
  secondaryBg: {
    backgroundColor: '#F9FBFD'
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    width: '100%',
    '@media only screen and (max-width: 960px)': {
      gridTemplateColumns: '100%'
    },
    '&.cont-2-1': {
      gridTemplateColumns: '60% 40%',
      '@media only screen and (max-width: 960px)': {
        gridTemplateColumns: '100%'
      }
    },
    '&.cont-1-2': {
      textAlign: 'right',
      gridTemplateColumns: '40% 60%',
      '@media only screen and (max-width: 960px)': {
        gridTemplateColumns: '100%'
      }
    }
  },
  order_1_xs: {
    '@media only screen and (max-width: 960px)': {
      order: 1
    }
  },
  order_2_xs: {
    '@media only screen and (max-width: 960px)': {
      order: 2
    }
  },
  hide_xs: {
    visibility: 'visible',
    display: 'block',
    '@media only screen and (max-width: 960px)': {
      visibility: 'hidden',
      display: 'none'
    }
  },
  show_xs: {
    visibility: 'hidden',
    display: 'none',
    '@media only screen and (max-width: 960px)': {
      visibility: 'visible',
      display: 'block'
    }
  },
  // --- Grid ---
  // --- Font ---
  textHeader: {
    fontWeight: 700,
    fontSize: 48,
    lineHeight: '58px',
    // fontSize: 40,
    // lineHeight: '50px',
    color: '#414042',
    marginBottom: 40,
    '@media only screen and (max-width: 960px)': {
      fontSize: 38,
      lineHeight: '48px'
    },
    '@media only screen and (max-width: 425px)': {
      fontSize: 28,
      lineHeight: '38px',
      marginBottom: 0
    },
    '&.ta-center': {
      textAlign: 'center'
    },
    '&.mb-0': {
      marginBottom: 0
    }
  },
  textSlogan: {
    marginTop: 20,
    fontWeight: 500,
    color: '#414042',
    fontSize: 20,
    lineHeight: '30px',
    '@media only screen and (max-width: 960px)': {
      textAlign: 'center'
    },
    '@media only screen and (max-width: 425px)': {
      fontSize: 16,
      lineHeight: '26px'
    },
    '&.mt-5': {
      marginTop: 5
    },
    '&.t-20': {
      position: 'relative',
      top: -20,
      '@media only screen and (max-width: 960px)': {
        top: 0
      }
    },
    '&.t-15': {
      position: 'relative',
      top: -15,
      '@media only screen and (max-width: 960px)': {
        top: 0
      }
    },
    '&.ta-center': {
      textAlign: 'center'
    }
  },
  textGradient: {
    fontSize: 'inherit',
    background: '-webkit-linear-gradient(180deg, #8656da, #0082f9)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent'
    // margin: '0 10px',
    // '@media only screen and (max-width: 320px)': {
    //   margin: '0 10px'
    // }
  },
  typewriterGradient: {
    '& .Typewriter__wrapper': {
      fontSize: 'inherit',
      background: '-webkit-linear-gradient(180deg, #8656da, #0082f9)',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent'
    }
  },
  title: {
    fontSize: 40,
    fontWeight: 700,
    lineHeight: '50px',
    marginBottom: 60,
    textAlign: 'center',
    color: '#414042',
    '@media only screen and (max-width: 960px)': {
      fontSize: 35,
      lineHeight: '45px'
    },
    '@media only screen and (max-width: 425px)': {
      fontSize: 26,
      lineHeight: '36px',
      marginBottom: 10
    },
    '@media only screen and (max-width: 320px)': {
      fontSize: 24,
      lineHeight: '34px'
    }
  },
  subTitle: {
    fontSize: 32,
    fontWeight: 600,
    lineHeight: '42px',
    color: '#414042',
    '@media only screen and (max-width: 425px)': {
      fontSize: 24,
      lineHeight: '34px'
    },
    '@media only screen and (max-width: 320px)': {
      fontSize: 22,
      lineHeight: '32px'
    }
  },
  mediumText: {
    fontSize: 22,
    fontWeight: 600,
    lineHeight: '32px',
    // marginBottom: 32,
    // textAlign: 'left',
    color: '#414042'
  },
  text: {
    marginBottom: 30,
    fontSize: 16,
    lineHeight: '28px',
    fontWeight: 400,
    color: '#414042',
    '@media only screen and (max-width: 425px)': {
      fontSize: 14
    }
  },
  // --- Font ---
  // --- Components ---
  button: {
    textDecoration: 'none',
    fontSize: 18,
    lineHeight: '28px',
    fontWeight: 600,
    backgroundImage: '-webkit-linear-gradient(180deg, #8656da, #0082f9)',
    textTransform: 'capitalize',
    color: '#fff',
    padding: '10px 30px',
    borderRadius: '45px',
    transition: 'all .5',
    '&:hover': {
      backgroundImage: '-webkit-linear-gradient(180deg, #764dbd, #026cce)'
    },
    '@media only screen and (max-width: 425px)': {
      fontSize: '16px',
      lineHeight: '18px'
    }
  },
  // --- Components ---
  // --- Section Header ---
  sloganContainer: {
    // paddingTop: 80,
    paddingTop: 60,
    '@media only screen and (max-width: 960px)': {
      paddingTop: 30,
      textAlign: 'center'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
    '&.btn-left': {
      justifyContent: 'flex-start',
      '@media only screen and (max-width: 960px)': {
        justifyContent: 'center'
      }
    },
    '&.btn-right': {
      justifyContent: 'flex-end',
      '@media only screen and (max-width: 960px)': {
        justifyContent: 'center'
      }
    }
  },
  customButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.btn-left': {
      justifyContent: 'flex-start',
      '@media only screen and (max-width: 960px)': {
        justifyContent: 'center'
      }
    },
    '&.btn-right': {
      justifyContent: 'flex-end',
      '@media only screen and (max-width: 960px)': {
        justifyContent: 'center'
      }
    }
  },
  mainSectionImgBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerSectionImgBox: {
    padding: 20,
    margin: 'auto 0'
  },
  headerSectionImg: {
    width: 'auto',
    height: 600
  },
  headerTypesContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: 40,
    gridAutoRows: '50%',
    width: '100%',
    marginTop: 50,
    '@media only screen and (max-width: 960px)': {
      gridTemplateColumns: '100%'
    }
  },
  headerTypesBox: {
    width: '100%',
    '& span': {
      color: '#414042',
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '28px',
      color: '#414042',
      '& i': {
        background: '#0082f9',
        padding: '0px 12px',
        marginRight: 7,
        borderRadius: '50%'
      }
    },
    '& p': {
      // marginTop: 20,
      fontWeight: 400,
      color: '#414042',
      fontSize: 18,
      textAlign: 'justify',
      lineHeight: '28px',
      '@media only screen and (max-width: 425px)': {
        fontSize: 16,
        lineHeight: '26px'
      }
    },
    '&.type-2': {
      '& i': {
        background: '#8656da'
      }
    }
  },
  // --- Section Header ---
  // --- Section 1: Companies ---
  marqueeContainer: {
    // backgroundColor: '#07003B',
    backgroundImage: '-webkit-linear-gradient(180deg, #8656da, #0082f9)',
    position: 'relative'
  },
  marqueeText: {
    width: '50%',
    fontSize: 21,
    fontWeight: 400,
    lineHeight: '31px',
    textAlign: 'center',
    color: '#fff',
    marginTop: 80,
    marginBottom: 40,
    '@media only screen and (max-width: 425px)': {
      marginTop: 40,
      marginBottom: 20,
      fontSize: 16,
      lineHeight: '26px',
      width: '90%'
    },
    '@media only screen and (max-width: 320px)': {
      fontSize: 14,
      lineHeight: '24px'
    }
  },
  '@keyframes marquee': { '0%': { left: '0' }, '100%': { left: '-1100px' } },
  marquee: {
    paddingBottom: 120,
    zIndex: '0',
    height: '170px',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    '@media only screen and (max-width: 425px)': {
      height: '120px'
    }
  },
  // transparenceCover: {
  //   position: 'absolute',
  //   zIndex: '10',
  //   height: '100%',
  //   width: '100%',
  //   background:
  //     'linear-gradient(to right,#07003b 0,rgb(7 0 59 / 20%) 10%,rgb(7 0 59 / 20%) 90%,#07003b 100%)'
  // },
  marqueeDiv: {
    display: 'block',
    width: '5120px',
    position: 'absolute',
    overflow: 'hidden',
    animation: `$marquee 90s linear infinite`
  },
  marqueeImg: {
    float: 'left',
    height: '120px',
    marginRight: 70
  },
  // --- Section 1: Companies ---
  // ---  Section 2: Woxo Products ---
  productContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 60px 60px 60px',
    borderRadius: 8,
    overflow: 'hidden',
    '& > div': {
      zIndex: 1,
      marginBottom: 15,
      '& span': {
        background: '#0082f9',
        padding: '0px 12px',
        marginRight: 7,
        borderRadius: '50%'
        // '@media only screen and (max-width: 425px)': {
        //   padding: '0px 11px'
        // }
      }
    },
    '& > span': {
      marginBottom: 10,
      marginTop: 5,
      zIndex: 1,
      minHeight: 140,
      '@media only screen and (max-width: 425px)': {
        minHeight: 0
      }
    },
    '& > h2': {
      marginTop: 5,
      zIndex: 1,
      minHeight: 130,
      '@media only screen and (max-width: 425px)': {
        minHeight: 0
      }
    },
    transition: 'all .3',
    background: 'rgba(0, 130, 249, .01)',
    '&:hover': {
      background: 'rgba(0, 130, 249, .04)'
    },
    '@media only screen and (max-width: 425px)': {
      padding: '20px 30px 30px 30px'
    }
  },
  productContainer__2: {
    '& > div': {
      '& span': {
        background: '#8656da'
      }
    },
    background: 'rgba(132, 86, 218, .01)',
    '&:hover': {
      background: 'rgba(132, 86, 218, .04)'
    },
    '& $productFig': {
      backgroundImage: 'radial-gradient(circle farthest-side at 50% 50%,#8656da,rgba(225,78,56,0))'
    },
    '& $productLink': {
      color: '#8656da'
    }
  },
  productFig: {
    position: 'absolute',
    left: '-25%',
    top: '-25%',
    right: 'auto',
    bottom: 'auto',
    zIndex: 0,
    width: 400,
    height: 280,
    borderRadius: '13%',
    backgroundColor: 'transparent',
    backgroundImage: 'radial-gradient(circle farthest-side at 50% 50%,#0082f9,rgba(225,78,56,0))',
    opacity: '.4',
    '-webkit-filter': 'blur(80px)',
    filter: 'blur(80px)'
  },
  productLink: {
    fontSize: 16,
    lineHeight: '28px',
    fontWeight: 700,
    color: '#0082f9',
    marginTop: 20,
    '& svg': {
      position: 'relative',
      top: 7
    },
    cursor: 'pointer',
    textDecoration: 'none'
  },
  productImgBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 60,
    '@media only screen and (max-width: 425px)': {
      marginTop: 20
    }
  },
  mainSectionImg: {
    width: '100%',
    height: 'auto',
    '& .img_90': {
      width: '90%'
    },
    '@media only screen and (max-width: 960px)': {
      width: '90%'
    }
  },
  // ---  Section 2: Woxo Products ---
  // --- Section 3: Meet Woxo Video Maker ---
  removeStyles: {
    '& h1': {
      fontSize: 30,
      lineHeight: '40px',
      marginBottom: 0,
      '@media only screen and (max-width: 425px)': {
        fontSize: 22,
        lineHeight: '32px'
      },
      '@media only screen and (max-width: 425px)': {
        fontSize: 20,
        lineHeight: '30px'
      }
    },
    '& h2': {
      marginBottom: 30,
      '@media only screen and (max-width: 425px)': {
        fontSize: 26,
        lineHeight: '36px'
      },
      '@media only screen and (max-width: 320px)': {
        fontSize: 24,
        lineHeight: '34px'
      }
    },
    '& $textSlogan': {
      textAlign: 'center'
    }
  },
  panelImageContainer: {
    marginTop: 40,
    marginBottom: 40,
    borderRadius: 12,
    overflow: 'hidden',
    // boxShadow: '0 50px 80px 0 rgb(0 11 40 / 8%)',
    boxShadow: '0 20px 40px 0 rgb(0 11 40 / 4%)',
    maxWidth: 750,
    margin: '0 auto'
  },
  // --- Section 3: Meet Woxo Video Maker ---
  // --- Section 6: Woxo Widgets ---
  contentBox: {
    '& .text_slogan_1': {
      marginBottom: 20,
      fontWeight: 700
    },
    '& p:last-child': {
      width: '60%',
      margin: '0 auto',
      textAlign: 'center'
    },
    '& h1': {
      marginBottom: 30
    }
  },
  // --- Section 6: Woxo Widgets ---
  // --- Section 7: Call to Action ---
  ctaContainer: {
    height: '400px'
    // '@media only screen and (max-width: 425px)': {
    //   paddingTop: 150,
    //   height: '400px'
    // }
  },
  ctaContainer__fig_box: {
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    bottom: 0,
    left: 0
  },
  ctaContainer__fig_box__fig_1: {
    position: 'absolute',
    width: '38%',
    height: 'auto',
    bottom: -1,
    left: 0,
    '@media only screen and (max-width: 960px)': {
      width: '49%'
    }
  },
  ctaContainer__fig_box__fig_2: {
    position: 'absolute',
    width: '38%',
    height: 'auto',
    bottom: -1,
    right: 0,
    '@media only screen and (max-width: 960px)': {
      width: '49%'
    }
  },
  ctaInfo: {
    textAlign: 'center',
    '& h2': {
      fontWeight: 700
    },
    '& h1': {
      marginTop: 30,
      marginBottom: 30
    },
    '& $buttonContainer': {
      marginBottom: 20
    }
  },
  ctaLink: {
    fontSize: 18,
    lineHeight: '28px',
    fontWeight: 700,
    color: '#414042',
    marginTop: 20,
    cursor: 'pointer'
    // textDecoration: 'none'
  },
  // --- Section 7: Call to Action ---
  // --- Section WOXO video features ---
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  featureContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'wrap'
  },
  featureBox: {
    width: '260px',
    height: 'auto',
    margin: '0 40px 60px 0',
    '@media only screen and (max-width: 425px)': {
      margin: '0 0 40px 0'
    }
  },
  featureIcon: {
    '& svg': {
      width: 40,
      height: 40,
      color: '#0082f9',
      fill: '#0082f9'
    },
    '&.featureIcon__2': {
      '& svg': {
        color: '#8656da',
        fill: '#8656da'
      }
    }
  },
  featureTitle: {
    fontSize: 18,
    fontWeight: 700,
    color: '#414042',
    marginBottom: 12,
    marginTop: 5
  },
  featureDesc: {
    fontSize: 16,
    fontWeight: 400,
    color: '#414042'
  },
  // --- Section WOXO video features ---
  videoLazyContainer: {
    '@media only screen and (max-width: 425px)': {
      minHeight: '170px'
    },
    minHeight: '380px'
  },
  panelImageContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 40,
    borderRadius: 12
  },
  toolImage: {
    borderRadius: 12,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      filter: 'brightness(0.95)'
    }
  }
}));
